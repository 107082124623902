import React from 'react';
import { FaTree } from 'react-icons/fa'; // Icona albero di Natale

function Eventi() {
  const eventi = [
    {
      titolo: "Mittag geöffnet",
      data: "24 Dezember 2024",
      descrizione: "Frische Austern, Weißwürste oder à la carte.",
      extraText: "Warme Küche bis 14:30 Uhr.",
    },
  ];

  const renderEvento = (evento, index) => (
    <div
      key={index}
      className="mb-6 bg-red-900 rounded-lg overflow-hidden p-8 text-[#F4E1C1] border-4 border-yellow-500 shadow-lg"
    >
      {/* Icona albero di Natale */}
      <div className="flex justify-center mb-4">
        <FaTree className="text-green-500 text-6xl" />
      </div>
      {/* Titolo */}
      <h4 className="text-5xl font-extrabold mb-6 text-center">{evento.titolo}</h4>
      {/* Data */}
      <p className="text-3xl font-bold mb-6 text-center">{evento.data}</p>
      {/* Descrizione */}
      <p className="text-2xl italic mb-6 text-center">{evento.descrizione}</p>
      {/* Extra Testo */}
      {evento.extraText && (
        <p className="text-2xl italic text-center">{evento.extraText}</p>
      )}
    </div>
  );

  return (
    <div className="bg-[#A52A2A] min-h-screen p-8">
      <h3 className="text-4xl font-extrabold mb-8 text-gold text-center">
      Spezielle Weihnachts Events
      </h3>
      {eventi.map(renderEvento)}
    </div>
  );
}

export default Eventi;