import React from 'react';

function Aperitifs() {
  const aperitifs = [
    {
        nome: 'Bicicletta Cocktail',
        descrizione: 'Campari, Soda und Weißwein',
        prezzo: 7.50,
      },
    {
      nome: 'Aperol Spritz',
      descrizione: 'Aperol, Prosecco, Soda',
      prezzo: 9.90,
    },
    {
      nome: 'Campari Spritz',
      descrizione: 'Campari, Prosecco, Soda',
      prezzo: 9.90,
    },
    {
      nome: 'Hugo',
      descrizione: 'Holunderblütensirup, Prosecco, Soda, Minze',
      prezzo: 9.90,
    },
    // Aggiungi altri aperitivi qui
  ];

  const renderAperitif = (aperitif, index) => (
    <React.Fragment key={index}>
      {index > 0 && <hr className="my-4 border-[#F4E1C1] opacity-30" />}
      <div className="p-3 bg-[#A0522D] rounded-lg text-[#F4E1C1]">
        <h4 className="text-lg font-medium">{aperitif.nome}</h4>
        <p className="text-sm italic mb-2">{aperitif.descrizione}</p>
        <div className="flex justify-between mt-2">
          <span>Preis:</span>
          <span className="font-bold">{aperitif.prezzo.toFixed(2)} €</span>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-black">Unsere Aperitifs</h3>
      {aperitifs.map(renderAperitif)}
    </div>
  );
}

export default Aperitifs;