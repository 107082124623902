import React from 'react';
import caffeImage from './images/caffe.jpg';
import grappaImage from './images/grappa.jpg';
import melanzaneImage from './images/melanzane.jpg';
import passitoImage from './images/passito.jpg';
import oliveImage from './images/olive.jpg';
import peperoncinoImage from './images/peperoncino.jpg';

function Feinkost() {
  const emporio = [
    {
      nome: 'Kaffe Moka Dor',
      descrizione: '1 kg',
      prezzo: 18.0,
      immagine: caffeImage,
    },
    {
      nome: 'MELANZANE A FILETTI',
      descrizione: 'Auberginen in Streifen 3 kg',
      prezzo: 45.00,
      immagine: melanzaneImage,
    },
    {
      nome: 'OLIVE NOCELLARA',
      descrizione: '3 kg',
      prezzo: 45.00,
      immagine: oliveImage,
    },
    {
      nome: 'PEPERONCINO RIPIENO CON TONNO E CAPPERI',
      descrizione: 'Gefüllte Chilischoten mit Thunfisch und Kapern 3 kg',
      prezzo: 45.00,
      immagine: peperoncinoImage,
    },
    {
      nome: 'La Montanara Grappa di vinaccia',
      descrizione: '1 L',
      prezzo: 28.00,
      immagine: grappaImage,
    },
    {
      nome: 'Gewürztraminer Passito',
      descrizione: 'SÜDTIROL - ALTO ADIGE (Cresta 2020) 375 ml',
      prezzo: 26.00,
      immagine: passitoImage,
    },
  ];

  const renderEmporioItem = (item, index) => (
    <div
      key={index}
      className="flex flex-col sm:flex-row mb-6 bg-[#A0522D] rounded-lg overflow-hidden"
    >
      <div className="w-full sm:w-1/3 flex-shrink-0 card-3d-image">
        <img
          src={item.immagine}
          alt={item.nome}
          className="w-full h-full object-cover card-img-top"
        />
      </div>
      <div className="w-full sm:w-2/3 p-4">
        <h4 className="text-xl font-medium text-[#F4E1C1]">{item.nome}</h4>
        <p className="text-sm italic mb-2 text-[#F4E1C1]">{item.descrizione}</p>
        <div className="mt-2">
          <p className="text-[#F4E1C1]">
            Preis: <span className="font-bold">{item.prezzo.toFixed(2)} €</span>
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <h3 className="text-xl font-semibold mb-4 text-[#F4E1C1]">Unsere Feinkost</h3>
      {emporio.map(renderEmporioItem)}
    </div>
  );
}

export default Feinkost;