import React from 'react';

function Angebote() {
  const menu = {
    insalate: [
      {
        nome: 'Pastore Salat',
        descrizione: '(Gemischter Salat, Parmaschinken, Fetakäse, Oliven, Peperoni)',
        prezzo: 10.00,
      },
      {
        nome: 'Nizzarda Salat',
        descrizione: '(Gemischter Salat, Thunfisch, Zwiebel, Oliven, Peperoni, Ei)',
        prezzo: 10.00,
      },
      {
        nome: 'Paranza Salat',
        descrizione: '(Gemischter Salat, Bresaola, Mozzarella, Artischocken, Mais)',
        prezzo: 10.00,
      },
    ],
    pasta: [
      {
        nome: 'Spaghetti Arrabiata',
        descrizione: '(Tomatensoße, Knoblauch, Chilischote, Petersilie)',
        prezzo: 10.00,
      },
      {
        nome: 'Spaghetti Aglio Olio',
        descrizione: '(Knoblauch, Olivenöl, Chilischote, Petersilie, Kirschtomaten)',
        prezzo: 10.00,
      },
      {
        nome: 'Spaghetti Amatriciana',
        descrizione: '(Tomatensoße, Speck, Zwiebel, Petersilie)',
        prezzo: 10.00,
      },
      {
        nome: 'Spaghetti Napoli',
        descrizione: '(Tomatensoße, Basilikum)',
        prezzo: 10.00,
      },
      {
        nome: 'Spaghetti Quattro Formaggi',
        descrizione: '(Gorgonzola-Käse, Pecorino-Käse, Fontina-Käse, Parmesan-Käse)',
        prezzo: 10.00,
      },
      {
        nome: 'Spaghetti Carbonara',
        descrizione: '(Sahne, Speck, Eigelbe, schwarzer Pfeffer, Parmesan)',
        prezzo: 10.00,
      },
      {
        nome: 'Pasta Vegetaria',
        descrizione: '(Verschiedene Gemüse wie Zucchini, Auberginen, Paprika, Kirschtomaten, Spinat, Brokkoli)',
        prezzo: 10.00,
      },
    ],
    pizza: [
      {
        nome: 'Pizza Quattro Formaggi',
        descrizione: '(Tomatensoße, Mozzarella, Gorgonzola-Käse, Pecorino-Käse, Parmesan-Käse)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Diavolo',
        descrizione: '(Tomatensoße, Mozzarella, Peperoni, Salami, Kapern, Sardellen)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Capriciosa',
        descrizione: '(Tomatensoße, Mozzarella, Schinken, Salami, Oliven, Peperoni, Artischocken, Champignon-Pilze)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Caprese',
        descrizione: '(Tomatensoße, Mozzarella, Frische Tomaten, Mozzarella, Basilikum)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Parma',
        descrizione: '(Tomatensoße, Mozzarella, Parmaschinken)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Bresaola',
        descrizione: '(Tomatensoße, Mozzarella, Bresaola (Rinderschinken))',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Toscana',
        descrizione: '(Tomatensoße, Mozzarella, Fenchelsalami)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Pera',
        descrizione: '(Tomatensoße, Mozzarella, Gorgonzola, Birnen, Walnusskern)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Calabrese',
        descrizione: '(Tomatensoße, Mozzarella, Scharfe Salami)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Vegetaria',
        descrizione: '(Tomatensoße, Mozzarella, Verschiedene Gemüse)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Mortadella',
        descrizione: '(Tomatensoße, Mozzarella, Mortadella)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Salsiccia',
        descrizione: '(Tomatensoße, Mozzarella, Salsiccia)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Tonno',
        descrizione: '(Tomatensoße, Mozzarella, Thunfisch, Zwiebel)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Margherita',
        descrizione: '(Tomatensoße, Mozzarella)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Salami',
        descrizione: '(Tomatensoße, Mozzarella, Salami)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Regina',
        descrizione: '(Tomatensoße, Mozzarella, Schinken, Champignon-Pilze)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Quattro Stagioni',
        descrizione: '(Tomatensoße, Mozzarella, Schinken, Champignon-Pilze, Salami, Peperoni)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Wasily',
        descrizione: '(Tomatensoße, Mozzarella, Verschiedene Gemüse, Sardellen, Kapern, scharf)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Rustica',
        descrizione: '(Tomatensoße, Mozzarella, Verschiedene Sorten von Salami)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Hawai',
        descrizione: '(Tomatensoße, Mozzarella, Schinken, Ananas)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Salami e Prosciutto',
        descrizione: '(Tomatensoße, Mozzarella, Salami, Schinken)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Romana',
        descrizione: '(Tomatensoße, Mozzarella, schwarze Oliven, Sardellen, Kapern)',
        prezzo: 10.00,
      },
      {
        nome: 'Pizza Pane Rossa',
        descrizione: '(Tomatensoße, Mit oder ohne Knoblauch)',
        prezzo: 5.00,
      },
      {
        nome: 'Pizza Pane Bianca',
        descrizione: '(Rosmarin, Salz)',
        prezzo: 5.00,
      },
    ],
  };

  const renderMenuSection = (section) => (
    <div className="mb-6">
      {section.map((item, index) => (
        <div key={index} className="mb-4 bg-[#A0522D] p-4 rounded-lg">
          <h4 className="text-lg font-medium text-[#F4E1C1]">{item.nome}</h4>
          <p className="text-sm italic mb-2 text-[#F4E1C1]">{item.descrizione}</p>
          <p className="text-[#F4E1C1]">
            Preis Abholung: <span className="font-bold">{item.prezzo.toFixed(2)} €</span>
          </p>
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <h1 className="text-4xl font-extrabold mb-8 text-black-600 text-center">
        Unsere Abholangebote
      </h1>

      {/* Insalate */}
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-6 text-black-700 text-center">Vorspeisen</h2>
        {renderMenuSection(menu.insalate)}
      </div>

      {/* Pasta */}
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-6 text-black-700 text-center">Pasta</h2>
        {renderMenuSection(menu.pasta)}
      </div>

      {/* Pizza */}
      <div className="mb-8">
        <h2 className="text-3xl font-bold mb-6 text-black-700 text-center">Pizza</h2>
        {renderMenuSection(menu.pizza)}
      </div>

      <p className="text-black italic mt-6 text-2xl font-extrabold text-center">
        Angebot gültig nur zur Abholung. Wir freuen uns auf Ihre Bestellung!
      </p>
    </div>
  );
}

export default Angebote;